<template>
  <NavBar/>
  <CarouselView/>
  <div class="router">
    <RouterView/>
  </div>
  <FooterView/>
</template>


<script>
import NavBar from './components/NavbarView.vue';
import FooterView from './components/FooterView.vue';
import CarouselView from './components/CarouselView.vue'
export default {
  components: {
    NavBar,
    CarouselView,
    FooterView
  }
};
</script>
<style>
  @media (max-width: 1000px) {
    .router {
      margin-top: 80px;
    }
  }
</style>