<template>
  <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="3000">
        <img :style="getImgStyle()" src="https://i.postimg.cc/DzRjCLM7/c-orig.jpg" alt="First slide">
        <div class="carousel-caption">
          <h1>{{ pageTitle }}</h1>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="3000">
        <img :style="getImgStyle()" src="https://i.postimg.cc/ZKrS6K09/scc2-orig.jpg" alt="Second slide">
        <div class="carousel-caption">
          <h1>{{ pageTitle }}</h1>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="3000">
        <img :style="getImgStyle()" src="https://i.postimg.cc/sgQ05TTT/img3.png" alt="Third slide">
        <div class="carousel-caption">
          <h1>{{ pageTitle }}</h1>
        </div>
      </div>
      <div class="carousel-item" data-bs-interval="3000">
        <img :style="getImgStyle()" src="https://i.postimg.cc/PJQTLKHj/74-B6-A2-E6-EDF6-4-E44-939-C-29294378-E3-E0.jpg" alt="fourth slide">
        <div class="carousel-caption">
          <h1>{{ pageTitle }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .carousel {
    margin-top: 80px;
  }
  .carousel-caption {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  .carousel-caption h1 {
    margin-bottom: 0;
    text-shadow: 4px 4px black;
  }
  .img {
    width: 60%;
    height: 75%;
  }

  /* Media queries for responsive height adjustment */
  @media (max-width: 800px) {
    .carousel-caption,
    .img {
      height: 150px !important;
    }
  }
</style>

<script>
  export default {
    computed: {
      pageTitle() {
        const route = this.$route.path;
        if (route === '/') {
          return 'Airwall Solutions';
        } else if (route === '/repair/') {
          return 'Repair';
        } else if (route === '/maintenance/') {
          return 'Maintenance';
        } else if (route === '/recover/') {
          return 'Recover';
        } else if (route === '/replacement/') {
          return 'Replacement';
        } else if (route === '/book/') {
          return 'Book A Job!';
        } else if (route === '/contact/') {
          return 'Interested in one of our services?';
        } else if (route === '/careers/') {
          return 'Apply Today!';
        } else {
          return 'Default Title';
        }
      },
    },
    methods: {
      getImgStyle() {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 800) {
          return 'height: 150px; width: 100%;';
        } else {
          return 'height: 350px; width: 100%;';
        }
      },
    },
  };
</script>
