<template>
    <footer class="contact">
    <div class="content">
      <a href="mailto:cprusainczf@gmail.com" class="contact-button">Contact Us</a>
      <h5>Complete Partition Repair<br></h5> 
      <p>324 PO Box 870<br>Prosperity, SC 29127<br>SC: <a href="tel:+18033642500" class="contact-link">(803) 364-2500</a><br>NC: <a href="tel:+18036093386" class="contact-link">(803) 609-3386</a>
      </p> 
    </div>
  </footer>
</template>

<style>
    .contact{
    background-color: black;      
    color: white;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .content{
    margin-top: auto;
    margin-bottom: 10px;
  }
  .contact-button{
    font-size: 22px;
    font: bold;
    display: inline-block;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }

  .contact-button:hover {
    color: grey;
  }
  .contact-link {
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  .contact-link:hover {
    color: grey;
  }
</style>