<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" :class="{ 'navbar-opaque': !isTransparent }">
    <a class="navbar-brand" href="/">COMPLETE PARTITION REPAIR</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <router-link to="/" class="nav-item nav-link active">Home</router-link>
        <router-link to="/repair/" class="nav-item nav-link">Repair</router-link>
        <router-link to="/maintenance/" class="nav-item nav-link">Maintenance</router-link>
        <router-link to="/recover/" class="nav-item nav-link">Recover</router-link>
        <router-link to="/replacement/" class="nav-item nav-link">Replacement</router-link>
        <router-link to="/contact/" class="nav-item nav-link">Contact</router-link>
        <router-link to="/careers/" class="nav-item nav-link">Careers</router-link>
      </div>
    </div>
  </nav>
</template>

<style>
  nav a {
    font-weight: bold;
    color: #2c3e50;
    margin-left: 10px;
  }

  .navbar {
    min-height: 80px;
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 0.3s ease-in-out;
  }

  .navbar-opaque {
    background-color: rgba(255, 255, 255, 0.6) !important;
  }
</style>

<script>
  export default {
    data() {
      return {
        isTransparent: true,
      };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.isTransparent = scrollTop === 0;
      },
    },
  };
</script>
