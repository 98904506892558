import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/repair/',
    name: 'repair',
    component: () => import('../views/RepairView.vue')
  },
  {
    path: '/maintenance/',
    name: 'maintenance',
    component: () => import('../views/MaintenanceView.vue')
  },
  {
    path: '/recover/',
    name: 'recover',
    component: () => import('../views/RecoverView.vue')
  }
  ,
  {
    path: '/replacement/',
    name: 'replacement',
    component: () => import('../views/ReplacementView.vue')
  },
  {
    path: '/contact/',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/careers/',
    name: 'careers',
    component: () => import('../views/CareersView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
